<template>
  <v-dialog v-model="visible" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="cyan"
        depressed
        icon
        small
        @click="startFetchingData(addressId)"
      >
        <v-icon small>mdi-truck</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ siteName }}
      </v-card-title>

      <v-card-subtitle class="pt-1">
        Further Address Information
      </v-card-subtitle>

      <LoadingSpinner v-if="loadingLandlordInfo" />

      <div v-else class="pb-5">
        <v-tabs v-model="tab" slider-color="accent" grow>
          <v-tab v-for="item in tabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items class="px-5" v-model="tab">
          <v-tab-item>
            <FurtherInfoTab
              :contact-name="landlord.firstName"
              :telephone-number="landlord.contactNumber"
              :email="landlord.email"
              :address="{
                firstLine: landlord.add1,
                secondLine: landlord.add2,
                town: landlord.town,
                county: landlord.county,
                postcode: landlord.postcode
              }"
            />
            <div class="d-flex justify-end">
              <v-btn depressed dark text color="accent" right @click="openFormAndClose">
                Edit
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <FurtherInfoTab
              :contact-name="previousTenant.firstName"
              :telephone-number="previousTenant.contactNumber"
              :email="previousTenant.email"
              :address="{
                firstLine: previousTenant.add1,
                secondLine: previousTenant.add2,
                town: previousTenant.town,
                county: previousTenant.county,
                postcode: previousTenant.postcode
              }"
            />
            <div class="d-flex justify-end">
              <v-btn depressed dark text color="accent" right @click="openPreviousFormAndClose">
                Edit
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <FurtherInfoTab
              :company-name="brewery.name"
              :brewery-owned="brewery.owned"
              :contact-name="brewery.firstName"
              :telephone-number="brewery.contactNumber"
              :email="brewery.email"
              :address="{
                firstLine: brewery.add1,
                secondLine: brewery.add2,
                town: brewery.town,
                county: brewery.county,
                postcode: brewery.postcode
              }"
            />
            <div class="d-flex justify-end">
              <v-btn depressed dark text color="accent" right @click="openBreweryFormAndClose">
                Edit
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <v-divider></v-divider>

      <!-- <v-card-actions>
        <v-btn text @click="visible = false">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue" text @click="openFormAndClose">
          Edit
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import LoadingSpinner from "@/components/loading-spinner"
import FurtherInfoTab from "@/components/further-info-tab"

export default {
  components: {
    LoadingSpinner,
    FurtherInfoTab
  },
  props: {
    addressId: {
      type: String,
      default: ""
    },
    siteName: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    visible: false,
    tab: null,
    tabs: ["Landlord", "Previous Tenant", "Brewery"]
  }),
  computed: {
    ...mapGetters("addresses", [
      "landlord",
      "previousTenant",
      "brewery",
      "loadingLandlordInfo"
    ])
  },
  methods: {
    ...mapActions("addresses", [
      "fetchLandlordInfo",
      "fetchPreviousTenantInfo",
      "fetchBreweryInfo"
    ]),
    ...mapActions("ui", ["openForm"]),
    openFormAndClose() {
      this.visible = false
      this.openForm({ formName: "landlord-info", edit: true })
    },
    openPreviousFormAndClose() {
      this.visible = false
      this.openForm({ formName: "previous-tenant", edit: true })
    },
    openBreweryFormAndClose() {
      this.visible = false
      this.openForm({ formName: "brewery-info", edit: true })
    },
    startFetchingData(id) {
      this.fetchLandlordInfo(id)
      this.fetchPreviousTenantInfo(id)
      this.fetchBreweryInfo(id)
    }
  }
}
</script>
