<template>
  <v-row class="mt-2 px-2">
    <v-col cols="6" v-if="breweryOwned">
      <DataValue
        label="Brewery Owned"
        :value="breweryOwned ? 'Yes' : 'No'"
        icon="factory"
        icon-color="deep-purple lighten-2"
      />
    </v-col>
    <v-col cols="6" v-if="companyName">
      <DataValue
        label="Company Name"
        :value="companyName"
        icon="office-building"
        icon-color="teal lighten-2"
      />
    </v-col>
    <v-col cols="6">
      <DataValue
        label="Contact Name"
        :value="contactName"
        icon="account"
        icon-color="accent lighten-2"
      />
    </v-col>
    <v-col cols="6">
      <DataValue
        label="Telephone"
        :value="telephoneNumber"
        icon="phone"
        icon-color="blue lighten-2"
      />
    </v-col>
    <v-col cols="6">
      <DataValue
        label="Email"
        :value="email"
        icon="email"
        icon-color="green lighten-2"
      />
    </v-col>

    <v-col cols="6">
      <DataValue
        label="Address"
        :value="address"
        icon="map-marker"
        icon-color="red lighten-2"
        address-type
      />
    </v-col>
  </v-row>
</template>

<script>
import DataValue from "./data-value"

export default {
  components: {
    DataValue
  },
  props: {
    companyName: {
      type: String,
      default: ""
    },
    contactName: {
      type: String,
      default: ""
    },
    telephoneNumber: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    },
    address: {
      type: Object,
      default: () => ({
        firstLine: "",
        secondLine: "",
        town: "",
        county: "",
        postcode: ""
      })
    },
    breweryOwned: {
      type: Boolean,
      default: false
    }
  }
}
</script>
