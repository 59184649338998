var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"cyan","depressed":"","icon":"","small":""},on:{"click":function($event){return _vm.startFetchingData(_vm.addressId)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-truck")])],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.siteName)+" ")]),_c('v-card-subtitle',{staticClass:"pt-1"},[_vm._v(" Further Address Information ")]),(_vm.loadingLandlordInfo)?_c('LoadingSpinner'):_c('div',{staticClass:"pb-5"},[_c('v-tabs',{attrs:{"slider-color":"accent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-tabs-items',{staticClass:"px-5",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('FurtherInfoTab',{attrs:{"contact-name":_vm.landlord.firstName,"telephone-number":_vm.landlord.contactNumber,"email":_vm.landlord.email,"address":{
              firstLine: _vm.landlord.add1,
              secondLine: _vm.landlord.add2,
              town: _vm.landlord.town,
              county: _vm.landlord.county,
              postcode: _vm.landlord.postcode
            }}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","dark":"","text":"","color":"accent","right":""},on:{"click":_vm.openFormAndClose}},[_vm._v(" Edit ")])],1)],1),_c('v-tab-item',[_c('FurtherInfoTab',{attrs:{"contact-name":_vm.previousTenant.firstName,"telephone-number":_vm.previousTenant.contactNumber,"email":_vm.previousTenant.email,"address":{
              firstLine: _vm.previousTenant.add1,
              secondLine: _vm.previousTenant.add2,
              town: _vm.previousTenant.town,
              county: _vm.previousTenant.county,
              postcode: _vm.previousTenant.postcode
            }}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","dark":"","text":"","color":"accent","right":""},on:{"click":_vm.openPreviousFormAndClose}},[_vm._v(" Edit ")])],1)],1),_c('v-tab-item',[_c('FurtherInfoTab',{attrs:{"company-name":_vm.brewery.name,"brewery-owned":_vm.brewery.owned,"contact-name":_vm.brewery.firstName,"telephone-number":_vm.brewery.contactNumber,"email":_vm.brewery.email,"address":{
              firstLine: _vm.brewery.add1,
              secondLine: _vm.brewery.add2,
              town: _vm.brewery.town,
              county: _vm.brewery.county,
              postcode: _vm.brewery.postcode
            }}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","dark":"","text":"","color":"accent","right":""},on:{"click":_vm.openBreweryFormAndClose}},[_vm._v(" Edit ")])],1)],1)],1)],1),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }